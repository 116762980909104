/**
 * Print styles
 *
 * http://www.webproducer.at/flexible-layout/
 */

/* Layout stylesheet */

* {
  background:white;
}

body {
  font-size: 100.01%; /* [1] */
  margin: 0px;
  padding: 0px;
  width:100%;
  background:white;
}

#col-b, #col-c, #footer, .print-no {
  display:none;
  background:white;
}

#header {
  text-indent:inherit;
  color:#000;
  background:white
}

#col-a {
  background:none;
  display:block;
  width:100%;
  height:100%;
  visibility:visible;
  color:#000;
}

.main-content {
  background:white;
  color:#000000
}
  
body, p, li {
  color:#000;
}

a {
  color: #999999;
  text-decoration:underline
}